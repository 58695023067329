import React from 'react'
import ProjectTable from '../components/ProjectTable'

function CurrentProject() {
  return (
    <div>
        <ProjectTable status={1}/>
    </div>
  )
}

export default CurrentProject