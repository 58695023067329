import React from 'react'
import UserTable from '../components/UserTable'
function ViewEmployee() {
  return (
    <div>
        <UserTable role={4}/>
    </div>
  )
}

export default ViewEmployee