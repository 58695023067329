import React from 'react'
import UserTable from '../components/UserTable'

function ViewAdmin() {
  return (
    <div>
        <UserTable role={2}/>
    </div>
  )
}

export default ViewAdmin