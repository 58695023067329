import React from 'react'
import ProjectTable from '../components/ProjectTable'

function FutureProject() {
  return (
    <div>
        <ProjectTable status={0}/>
    </div>
  )
}

export default FutureProject