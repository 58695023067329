import React from 'react'
import UserTable from '../components/UserTable'
function ViewSuperviser() {
  return (
    <div>
        <UserTable role={3}/>
    </div>
  )
}

export default ViewSuperviser